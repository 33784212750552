// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div class="contener">
            <span> </span>
            <span> </span>
            <span> </span>
            <span> </span>
            <span> </span>
        </div>

        <div className="coming">
          abanchiq shop powering-up soon! 
        </div>
    </div>
  );
}

export default App;
